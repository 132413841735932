import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 添加一项人工调整项
 * @param {Object} data
 */
export function addFeeAdjustItem (data) {
  return request({
    url: '/ooh-statement/agentbilladjust/addfeeadjustitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 修改人工调整项
 * @param {Object} data
 */
export function changeFeeAdjustItem (data) {
  return request({
    url: '/ooh-statement/agentbilladjust/changefeeadjustitem',
    method: 'post',
    data: qs.stringify(data)
  })
}
