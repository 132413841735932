import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询代理商账单
 * @param {Object} data
 */
export function getAgentBillList (data) {
  return request({
    url: '/ooh-statement/agentbill/getagentbilllist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询代理商某个结算周期账单
 * @param {Object} data
 */
export function getAgentBill (data) {
  return request({
    url: '/ooh-statement/agentbill/getagentbill',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取某代理商的合同真实发布情况
 * @param {Object} data
 */
export function getContractBillPage (data) {
  return request({
    url: '/ooh-statement/agentbill/getcontractbillpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取单个合同真实发布详情
 * @param {Object} data
 */
export function contractBillDetail (data) {
  return request({
    url: '/ooh-statement/agentbill/contractbilldetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取单个合同真实发布详情
 * @param {Object} data
 */
export function getContractExecutedDetail (data) {
  return request({
    url: '/ooh-statement/agentbill/getcontractexecuteddetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
