import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取某渠道下的合同真实发布情况
 * @param {Object} data
 */
export function getContractBillPage (data) {
  return request({
    url: '/ooh-scp/contractbill/getcontractbillpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取单个合同真实发布详情
 * @param {Object} data
 */
export function contractBillDetail (data) {
  return request({
    url: '/ooh-scp/contractbill/contractbilldetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取合同信息(通用版)
 * @param {Object} data
 */
export function getContractDetail (data) {
  return request({
    url: '/ooh-scp/contractbill/getcontractdetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
