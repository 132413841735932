const wNumb = require('wnumb')

const formater = wNumb({
  mark: '.',
  thousand: ',',
  decimals: 2,
  prefix: '¥ '
})
const formater2 = wNumb({
  mark: '',
  thousand: ',',
  decimals: 0,
  prefix: ''
})
const formater3 = wNumb({
  mark: '.',
  thousand: ',',
  decimals: 2,
  prefix: ''
})

export function toMoney (number) {
  let moneyNumber = Number(number)
  if (Number.isNaN(moneyNumber)) {
    moneyNumber = 0
  }
  return formater.to(moneyNumber)
}

export function toNumber (number) {
  let moneyNumber = Number(number)
  if (Number.isNaN(moneyNumber)) {
    moneyNumber = 0
  }
  return formater2.to(moneyNumber)
}

export function toMoneyNoPrefix (number) {
  let moneyNumber = Number(number)
  if (Number.isNaN(moneyNumber)) {
    moneyNumber = 0
  }
  return formater3.to(moneyNumber)
}
