<style lang="less" scoped>
</style>
<template>
  <div>
    <Row class="m-b-10">
      <i-col span="12"></i-col>
      <i-col span="12"></i-col>
    </Row>
    <Card
      title="基本信息"
      class="m-b-10"
    >
      <Row class="m-b-10">
        <i-col span="6"><strong>编号：{{contractInfo.contractCode}}</strong></i-col>
        <i-col span="6"><strong>客户：{{contractInfo.customerName}}</strong></i-col>
        <i-col span="6"><strong>品牌：{{contractInfo.name}}</strong></i-col>
        <i-col span="6"><strong>创建者：{{contractInfo.userName}}</strong></i-col>
      </Row>
      <Row>
        <i-col span="6"><strong>档期时间：{{contractInfo.contractStartDate}} 至 {{contractInfo.contractEndDate}}</strong></i-col>
        <i-col span="6"><strong>创建时间：{{contractInfo.createTime}}</strong></i-col>
        <i-col span="6"><strong>发布类型{{contractInfo.contractCategoryName}}</strong></i-col>
      </Row>
    </Card>
    <Card title="当期费用类别">
      <div
        slot="extra"
        v-if="serviceType===1"
      >
        <Button
          type="primary"
          size="small"
          @click="handleAdd"
        >添加人工调整</Button>
      </div>
      <Tabs
        @on-click="changeType"
        v-model="selectedFeeType"
      >
        <TabPane
          v-for="(item,index) in feeTypeList"
          :key="index"
          :label="item.feeTypeName"
          :name="item.feeType.toString()"
        >
          <Table stripe
            :data="feeItemList"
            :columns="feeItemColumns"
          ></Table>
        </TabPane>
      </Tabs>
    </Card>
    <Modal
      v-model="modalAdd"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >添加人工调整</p>
      <Form
        ref="adjustment"
        :model="formData"
        :rules="formDataValidate"
        label-position="top"
      >
        <FormItem
          label="费用类型"
          prop="feeType"
        >
          <Select
            v-model="formData.feeType"
            placeholder="请选择费用类型"
            :disabled="feeTypeDisabled"
            @on-change="handleChangeFeeType"
          >
            <Option
              v-for="item in feeTypeList"
              :key="'ft_'+item.feeType"
              :value="item.feeType"
            >{{item.feeTypeName}}</Option>
          </Select>
        </FormItem>
        <FormItem
          label="调整项金额"
          prop="adjustAmount"
        >
          <InputNumber
            v-model="formData.adjustAmount"
            style="width:100%"
            placeholder="请填写调整项金额"
          ></InputNumber>
        </FormItem>
        <FormItem label="调整说明">
          <Input
            v-model="formData.mark"
            type="textarea"
            :autosize="{minRows: 2,maxRows: 5}"
            placeholder="调整说明"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button
          type="default"
          @click="modalAdd=false"
        >取消</Button>
        <Button
          type="primary"
          @click="handleSave"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
// import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getContractDetail } from '@/api/scp/contractbill'
// import { contractBillDetail } from '@/api/statement/agentbill'
// import { listContractExecuteDetail } from '@/api/dw/verify'
import { getContractExecutedDetail } from '@/api/statement/agentbill'
import { addFeeAdjustItem, changeFeeAdjustItem } from '@/api/statement/agentbilladjust'
export default {
  data () {
    return {

      contractInfo: {},
      feeList: [],
      feeTypeList: [],
      feeItemList: [],
      selectedFeeType: null,
      executeTypeArray: new Map([
        [1, '系统生成'],
        [2, '系统调整'],
        [3, '人工调整']
      ]),
      feeItemColumns: [
        {
          title: '发生时间',
          align: 'center',
          render: (h, param) => {
            // return h('span', GetCurrentSchedule(param.row.startDate, param.row.endDate))
            return h('span', `${param.row.startDate} 至 ${param.row.endDate}`)
          }
        },
        {
          title: '记录方式',
          align: 'center',
          key: 'executeType',
          render: (h, param) => {
            // return h('span', toMoney(param.row.signAmount))
            return h('span', this.executeTypeArray.get(param.row.executeType))
          }
        },
        {
          title: '合同执行额',
          align: 'center',
          key: 'amount',
          render: (h, param) => {
            return h('div', [
              h('span', {
                style: { marginRight: '5px' }
              }, toMoney(param.row.amount)),
              param.row.executeType === 3 && this.serviceType === 1 ? h('a', {
                on: {
                  click: () => {
                    this.optionType = 2
                    this.feeTypeDisabled = true
                    this.formData.feeType = parseInt(this.selectedFeeType)
                    this.formData.adjustAmount = param.row.amount
                    this.formData.mark = param.row.executeRemark
                    this.modalAdd = true
                  }
                }
              }, '编辑') : null
            ])
          }
        },
        {
          title: '备注',
          align: 'center',
          key: 'executeRemark'
        }
      ],
      modalAdd: false,
      feeTypeDisabled: false,
      optionType: 1, // 1:新增，2:修改
      formData: {
        bid: null,
        companyId: null,
        feeType: null,
        adjustAmount: null,
        mark: ''

      },
      formDataValidate: {
        feeType: [
          { required: true, type: 'number', message: '请选择费用类型', trigger: 'change' }
        ],
        adjustAmount: [
          { required: true, type: 'number', message: '请填写调整项金额', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getContractInfo()
    this.getBillDetail()
  },
  computed: {
    bill () {
      return this.$store.state.reconciliation.bill
    },
    contractQuery () {
      return this.$store.state.reconciliation.contractQuery
    },
    serviceType () {
      return this.$store.state.reconciliation.serviceType
    }
  },
  methods: {
    getContractInfo () {
      getContractDetail({ bid: this.contractQuery.id }).then(res => {
        if (res && !res.errcode) {
          this.contractInfo = res
        }
      })
    },
    getBillDetail () {
      const query = {
        bid: parseInt(this.contractQuery.id),
        companyId: this.bill.companyId,
        publisherId: this.bill.publisherId,
        startDate: this.bill.startDate,
        endDate: this.bill.endDate
      }
      getContractExecutedDetail(query).then(res => {
        if (res && !res.errcode) {
          this.feeList = res
          this.feeTypeList = res.map(x => {
            return {
              feeType: x.feeType,
              feeTypeName: x.feeTypeName
            }
          })
          this.selectedFeeType = this.feeTypeList[0].feeType.toString()
          this.feeItemList = res[0].detailItemList
        } else {
          this.feeList = []
        }
      })
    },
    changeType (name) {
      const feeType = parseInt(name)
      this.feeItemList = this.feeList.find(x => x.feeType === feeType).detailItemList
    },
    /**
     * 新增编辑中更改费用类型
     */
    handleChangeFeeType () {
      if (this.optionType === 1 && this.formData.feeType) {
        const itemList = this.feeList.find(x => x.feeType === this.formData.feeType).detailItemList
        if (itemList.some(x => x.executeType === 3)) {
          this.$Notice.error({ title: '无法操作', desc: '当前所选费用类型已存在人工调整项，若需调整，请前往编辑' })
          return false
        }
      }
    },
    handleAdd () {
      this.optionType = 1
      this.feeTypeDisabled = false
      this.formData.feeType = null
      this.formData.adjustAmount = null
      this.formData.mark = ''
      this.modalAdd = true
    },
    /**
     * 保存人工调整
     */
    handleSave () {
      this.$refs.adjustment.validate((valid) => {
        this.formData.bid = this.contractInfo.bid
        this.formData.companyId = this.contractInfo.companyId
        this.formData.publisherId = this.bill.publisherId
        this.formData.startDate = this.bill.startDate
        this.formData.endDate = this.bill.endDate
        this.formData.versionId = this.versionId

        if (valid) {
          this.modalAdd = false
          if (this.optionType === 1) { // 新增
            addFeeAdjustItem(this.formData).then(res => {
              if (res && res.errcode === 0) {
                this.getBillDetail()
                this.$Notice.success({ desc: '操作成功' })
              } else {
                this.$Notice.error({ title: '操作失败', desc: res.errmsg })
              }
            })
          } else {
            changeFeeAdjustItem(this.formData).then(res => {
              if (res && res.errcode === 0) {
                this.getBillDetail()
                this.$Notice.success({ desc: '操作成功' })
              } else {
                this.$Notice.error({ title: '操作失败', desc: res.errmsg })
              }
            })
          }
        }
      })
    }
  }
}
</script>
